import {
  GET_DESIGNATION, UPDATE_DESIGNATION, ADD_DESIGNATION,
  GET_STATUS, UPDATE_STATUS, ADD_STATUS,
  GET_TRAINING, UPDATE_TRAINING, ADD_TRAINING,
  tabConst
} from "../actions/settingsActions";

const initState = {
  settingTabs: Object.values(tabConst),
  [tabConst.designation]: [],
  [tabConst.training]: [],
  [tabConst.status]: [],
  statusConst: {},
}

const settingsReducers = (state = initState, action) => {
  switch (action.type) {
    case ADD_DESIGNATION:
      return { ...state, designation: [action.payload, ...state.designation] }
    case GET_DESIGNATION:
      return { ...state, designation: action.payload.items }
    case UPDATE_DESIGNATION:
      return {
        ...state, designation: state.designation.map((ele) => {
          return action.payload._id === ele._id ? {
            ...action.payload
          } : ele;
        })
      }
    case ADD_STATUS:
      return { ...state, status: [action.payload, ...state.status] }
    case GET_STATUS:
      return { ...state, status: action.payload.items, statusConst: action.payload.statusConst }
    case UPDATE_STATUS:
      return {
        ...state, status: state.status.map((ele) => {
          return action.payload._id === ele._id ? {
            ...action.payload
          } : ele;
        })
      }
    case ADD_TRAINING:
      return { ...state, training: [action.payload, ...state.training] }
    case GET_TRAINING:
      return { ...state, training: action.payload.items }
    case UPDATE_TRAINING:
      return {
        ...state, training: state.training.map((ele) => {
          return action.payload._id === ele._id ? {
            ...action.payload
          } : ele;
        })
      }
    default:
      return state;
  }
}

export default settingsReducers;