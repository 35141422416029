import { baseUrl } from "../../config";
import { fetchWrapper } from '../../_helper'
export const GET_BRAND = 'GET_BRAND';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const ADD_BRAND = 'ADD_BRAND';

export const getBrand = (data = {}) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/brands`, data);
      dispatch({ type: GET_BRAND, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}

export const updateBrand = (id, data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/brands/update/${id}`, data);
      debugger
      dispatch({ type: UPDATE_BRAND, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}
export const addBrand = (data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/brands/add`, data);
      dispatch({ type: ADD_BRAND, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}



