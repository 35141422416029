import { GET_BRAND, ADD_BRAND, UPDATE_BRAND } from "../actions/brandActions";

const initState = {
  brand: [],
  loading: false,
}

const brandReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_BRAND:
      return { ...state, brand: action.payload }
    case ADD_BRAND:
      debugger
      return { ...state, brand: [action.payload, ...state.brand] }
    case UPDATE_BRAND:
      return {
        ...state, brand: state.brand.map((ele) => {
          return action.payload._id === ele._id ? {
            ...action.payload
          } : ele;
        })
      }
    default:
      return state;
  }
}

export default brandReducers;