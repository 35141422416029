import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { routesConst } from './../../constant'
import { AuthService } from './../../_services'


const ForGetPassword = ({ history }) => {
  const [formState, changeForm] = useState(false);
  const initialValues = {
    email: '',
    code: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    password: Yup.lazy(() => {
      if (formState) return Yup.string().required('Password is required');
      return Yup.string()
    }),
    confirmPassword: Yup.lazy(() => {
      if (formState) return Yup.string().oneOf([Yup.ref('password'), null], 'Confirm passwords must match with new password')
        .required('Confirm Password is Required')
      return Yup.string()
    }),
    code: Yup.lazy(() => {
      if (formState) return Yup.string().required('Code is required');
      return Yup.string()
    })
  });

  const onSubmit = async ({ email, password, code }, { setSubmitting }) => {
    try {
      if (formState) {
        const { message } = await AuthService.verifyCode({ email, password, code })
        toast.success(message)
        history.push(routesConst.routes.login.path)
      } else {
        changeForm(true)
        const { message } = await AuthService.forgetPassword({ email })
        toast.success(message)
      }
    } catch (error) {
      toast.error(error.message)
      setSubmitting(false);
    }
  }


  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ errors, touched, isSubmitting }) => {

                  return (
                    <FormikForm>

                      <div className="card-body">
                        <h6 className=" pb-3 text-center">Reset Password</h6>
                        {
                          !formState && <div className="form-group">
                            <label>Email ID</label>
                            <Field name="email" type="text" placeholder="Enter your email Id" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                          </div>
                        }
                        {
                          formState && <div className="form-group">
                            <label>Verification code</label>
                            <Field name="code" type="code" placeholder="Verification code" className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')} />
                            <ErrorMessage name="code" component="div" className="invalid-feedback" />
                          </div>
                        }
                        {
                          formState && <div className="form-group">
                            <label>New password</label>
                            <Field name="password" type="password" placeholder="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                          </div>
                        }
                        {
                          formState && <div className="form-group">
                            <label>Confirm password</label>
                            <Field name="confirmPassword" type="password" placeholder="confirm password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                          </div>
                        }

                        <div className="form-row mt-3 pt-1 pb-1">
                          <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-lg font-weight-medium btn-block auth-form-btn" >
                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            {formState ? "Reset" : "Send"}
                          </button>
                        </div>

                        <div className="text-center font-weight-light">
                          <Link to={routesConst.routes.login.path} className="btn btn-link pr-0">Login</Link>
                        </div>
                      </div>
                    </FormikForm>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForGetPassword
