import React, { useEffect } from 'react';
import { logout } from './../../store/actions/authActions'
import { useDispatch } from 'react-redux'

const Navbar = () => {
  const dispatch = useDispatch()
  //const toggleOffcanvas = () => {
  //  document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  //}
  useEffect(() => {
    //document.body.classList.toggle('sidebar-icon-only')
  }, [])
  //render() {
  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        {/*<button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <i className="mdi mdi-menu"></i>
        </button>*/}

        <ul className="navbar-nav navbar-nav-right ml-lg-auto">
          <li className="nav-item  nav-profile border-0" style={{ cursor: "pointer" }} onClick={() => dispatch(logout())}>
            <i className="fa fa-sign-out mr-0 p-1"></i>
            {'Sign Out'}
          </li>
        </ul>
        {/*<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>*/}
      </div>
    </nav>
  );
  //}
}

export default Navbar;
