import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux'
import { routesConst } from './../../constant';

class Sidebar extends Component {
  state = {
    route: {}
  };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  routeToggleMenuState(menuState) {
    if (this.state.route[menuState]) {
      this.setState((state) => ({ ...state, route: { ...state.route, [menuState]: false } }));
    } else if (Object.keys(this.state.route).length === 0) {
      this.setState((state) => ({ ...state, route: { ...state.route, [menuState]: true, } }));
    } else {
      //Object.keys(this.state.route).forEach(i => {
      //  this.setState((state) => ({ ...state, route: { ...state.route, [i]: false, }, }));
      //});
      this.setState((state) => ({ ...state, route: { ...state.route, [menuState]: true, }, }));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    //Object.keys(this.state).forEach(i => {
    //  this.setState({ [i]: false });
    //});

    const dropdownPaths = [
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar" >
        {/* <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html"><img src={require("../../../assets/images/logo.svg")} alt="logo" /></a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html"><img src={require("../../../assets/images/logo-mini.svg")} alt="logo" /></a>
        </div> */}
        < div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html" style={{ "textDecoration": "none" }}>Admin</a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html" style={{ "textDecoration": "none" }}>Admin</a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-profile not-navigation-link">
            <div className="nav-link">
              <div className="d-flex justify-content-between align-items-start">
                {/*<div className="profile-image mr-2">
                  <img src={require("../../../assets/images/faces/face8.jpg")} alt="profile" />
                </div>*/}
                <div className="text-left">
                  <p className="profile-name">
                    {/*{this.props.auth.user.name ? this.props.auth.user.name : "-"}
                    <small className="designation text-muted text-small"> ({this.props.auth.user.isAdmin ? " Admin" : " Mentor"})</small>*/}
                  </p>
                </div>
              </div>
            </div>
          </li>
          {
            Object.keys(routesConst.routes).map(ele => {
              const route = routesConst.routes[ele];
              //if (route && route.access.includes(this.props.auth.user.role)) {
              if (route && route.visibility.includes('sidebar')) {

                if (Object.keys(route.child || {}).length) {
                  return (
                    <li key={route.path} className={this.isPathActive(route.path) ? 'nav-item active' : 'nav-item'}>
                      <div className={this.state.route[route.menuName] ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.routeToggleMenuState(route.menuName)} data-toggle="collapse">
                        <i className={`${route.icon} menu-icon`}></i>
                        <span className="menu-title">{route.name}</span>
                        <i className="menu-arrow"></i>
                      </div>

                      <Collapse in={this.state.route[route.menuName]} >
                        <ul className="nav flex-column sub-menu">
                          {
                            Object.keys(route.child).map(subRouteKey => {
                              const childRoute = route.child[subRouteKey];
                              return (<li key={childRoute.path} className={this.isPathActive(childRoute.path) ? 'nav-item active' : 'nav-item'}>
                                <Link className={this.isPathActive(childRoute.path) ? 'nav-link active' : 'nav-link'} to={childRoute.path}>
                                  {childRoute.name}
                                </Link>
                              </li>);
                            })
                          }
                        </ul>
                      </Collapse>
                    </li>)
                }
                return (
                  <li key={route.path} className={this.isPathActive(route.path) ? 'nav-item active' : 'nav-item'}>
                    <Link className="nav-link" to={route.path}>
                      <i className={`${route.icon} menu-icon`}></i>
                      <span className="menu-title">{route.name}</span>
                    </Link>
                  </li>
                )
              }
              return null;
            })
          }

          {
            window.location.hostname === "localhosts" ? (<React.Fragment>
              <li className={this.isPathActive('/customtable') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/customtable">
                  <i className="mdi mdi-television menu-icon"></i>
                  <span className="menu-title">custom table</span>
                </Link>
              </li>

              <li className={this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
                <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
                  <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                  <span className="menu-title">Basic UI Elements</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.basicUiMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link'} to="/basic-ui/buttons">Buttons</Link></li>
                    <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link'} to="/basic-ui/dropdowns">Dropdowns</Link></li>
                    <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link'} to="/basic-ui/typography">Typography</Link></li>
                  </ul>
                </Collapse>
              </li>


              <li className={this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/form-elements/basic-elements">
                  <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                  <span className="menu-title">Form Elements</span>
                </Link>
              </li>


              <li className={this.isPathActive('/tables') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/tables/basic-table">
                  <i className="mdi mdi-table-large menu-icon"></i>
                  <span className="menu-title">Tables</span>
                </Link>
              </li>


              <li className={this.isPathActive('/icons') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/icons/font-awesome">
                  <i className="mdi mdi-account-box-outline menu-icon"></i>
                  <span className="menu-title">Icons</span>
                </Link>
              </li>


              <li className={this.isPathActive('/charts') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/charts/chart-js">
                  <i className="mdi mdi-chart-line menu-icon"></i>
                  <span className="menu-title">Charts</span>
                </Link>
              </li>


              <li className={this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item'}>
                <div className={this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('userPagesMenuOpen')} data-toggle="collapse">
                  <i className="mdi mdi-lock-outline menu-icon"></i>
                  <span className="menu-title">User Pages</span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.userPagesMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item"> <Link className={this.isPathActive('/blank-page') ? 'nav-link active' : 'nav-link'} to="/user-pages/blank-page">Blank Page</Link></li>
                    <li className="nav-item"> <Link className={this.isPathActive('/login') ? 'nav-link active' : 'nav-link'} to="/user-pages/login">Login</Link></li>
                    <li className="nav-item"> <Link className={this.isPathActive('/register') ? 'nav-link active' : 'nav-link'} to="/user-pages/register">Register</Link></li>
                    <li className="nav-item"> <Link className={this.isPathActive('/error-404') ? 'nav-link active' : 'nav-link'} to="/user-pages/error-404">404</Link></li>
                    <li className="nav-item"> <Link className={this.isPathActive('/error-500') ? 'nav-link active' : 'nav-link'} to="/user-pages/error-500">500</Link></li>
                  </ul>
                </Collapse>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-free/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
                  <i className="mdi mdi-file-outline menu-icon"></i>
                  <span className="menu-title">Documentation</span>
                </a>
              </li>
            </React.Fragment>) : null
          }


        </ul>
      </nav >
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

const mapDispatchToProps = (dispatch) => {
  return {}
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))