import React, { lazy } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { routesConst } from './../constant'
import Navbar from './../components/Navbar';
import Sidebar from './../components/Sidebar';
//import Footer from './../components/Footer';

const Dashboard = lazy(() => import('./../pages/Dashboard'));
const Sellers = lazy(() => import('./../pages/Sellers'));
const Products = lazy(() => import('./../pages/Products'));
const Brands = lazy(() => import('./../pages/Brands'));
const AuctionsList = lazy(() => import('./../pages/Auctions/list'));
const AuctionsBluk = lazy(() => import('./../pages/Auctions/bulk'));
const Category = lazy(() => import('./../pages/Category'));
const SellersProducts = lazy(() => import('./../pages/SellersProducts'));
const User = lazy(() => import('./../pages/User'));

//const ChangePassword = lazy(() => import('./../pages/ChangePassword'));
const Error404 = lazy(() => import('./../pages/Error404'));
const Error500 = lazy(() => import('./../pages/Error500'));
const Register1 = lazy(() => import('./../pages/auth/Register'));
const BlankPage = lazy(() => import('./../pages/BlankPage'));
const Buttons = lazy(() => import('./../components/basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./../components/basic-ui/Dropdowns'));
const Typography = lazy(() => import('./../components/basic-ui/Typography'));
const BasicElements = lazy(() => import('./../components/form-elements/BasicElements'));
const BasicTable = lazy(() => import('./../pages/tables/BasicTable'));
const FontAwesome = lazy(() => import('./../components/icons/FontAwesome'));
const ChartJs = lazy(() => import('./../components/charts/ChartJs'));



function ProtectedRouter() {

  let navbarComponent = <Navbar />;
  let sidebarComponent = <Sidebar />;
  //let footerComponent = <Footer />;

  const layoutStyle = { height: '100vh', "overflowY": "scroll", "overflowX": "hidden" }
  return (
    <div className="container-scroller">
      {navbarComponent}
      <div className="container-fluid page-body-wrapper">

        <div style={layoutStyle}>
          {sidebarComponent}
        </div>

        <div className="main-panel" style={layoutStyle}>
          <div className="content-wrapper">
            <Switch>
              <Route exact path={`/dashboard`} component={Dashboard} />
              <Route exact path={routesConst.routes.seller.path} component={Sellers} />
              <Route exact path={routesConst.routes.product.path} component={Products} />
              <Route exact path={routesConst.routes.brand.path} component={Brands} />
              <Route exact path={routesConst.routes.auction.child.list.path} component={AuctionsList} />
              <Route exact path={routesConst.routes.auction.child.bluk.path} component={AuctionsBluk} />
              <Route exact path={routesConst.routes.category.path} component={Category} />
              <Route exact path={routesConst.routes.users.path} component={User} />
              <Route exact path={routesConst.routes.sellerProduct.path} component={SellersProducts} />

              <Route exact path={routesConst.routes.orders.child.list.path} component={User} />
              <Route exact path={routesConst.routes.orders.child.download.path} component={User} />

              <Route exact path={routesConst.routes.users.child.overView.path} component={User} />
              <Route exact path={routesConst.routes.users.child.notification.path} component={User} />
              <Route exact path={routesConst.routes.users.child.userDetails.path} component={User} />

              <Route path="/basic-ui/buttons" component={Buttons} />
              <Route path="/basic-ui/dropdowns" component={Dropdowns} />
              <Route path="/basic-ui/typography" component={Typography} />
              <Route path="/form-Elements/basic-elements" component={BasicElements} />
              <Route path="/tables/basic-table" component={BasicTable} />
              <Route path="/icons/font-awesome" component={FontAwesome} />
              <Route path="/charts/chart-js" component={ChartJs} />
              <Route path="/register" component={Register1} />
              <Route path="/error-404" component={Error404} />
              <Route path="/error-500" component={Error500} />
              <Route path="/blank-page" component={BlankPage} />
              <Redirect to="/dashboard" />
            </Switch>
          </div>
          {/*{footerComponent}*/}
        </div>
      </div>
    </div>
  );

}

export default withRouter(ProtectedRouter);
