import { GET_AUCTION, ADD_AUCTION, UPDATE_AUCTION } from "../actions/auctionActions";

const initState = {
  auction: [],
  loading: false,
}

const auctionReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_AUCTION:
      return { ...state, auction: action.payload }
    case ADD_AUCTION:
      debugger
      return { ...state, auction: [action.payload, ...state.auction] }
    case UPDATE_AUCTION:
      return {
        ...state, auction: state.auction.map((ele) => {
          return action.payload._id === ele._id ? {
            ...action.payload
          } : ele;
        })
      }
    default:
      return state;
  }
}

export default auctionReducers;