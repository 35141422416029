import { baseUrl } from "../../config";
import { fetchWrapper } from '../../_helper'
export const GET_AUCTION = 'GET_AUCTION';
export const UPDATE_AUCTION = 'UPDATE_AUCTION';
export const ADD_AUCTION = 'ADD_AUCTION';

export const getAuction = (data = {}) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/auctions`, data);
      dispatch({ type: GET_AUCTION, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}

export const updateAuction = (id, data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/auctions/update/${id}`, data);
      debugger
      dispatch({ type: UPDATE_AUCTION, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}
export const addAuction = (data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/auctions/add`, data);
      dispatch({ type: ADD_AUCTION, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}



