import { baseUrl } from "../../config";
import { fetchWrapper } from '../../_helper'
export const GET_CATEGORY = 'GET_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const getCategory = (data = {}) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/category`, data);
      dispatch({ type: GET_CATEGORY, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}

export const addCategory = (data = {}) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/category`, data);
      dispatch({ type: GET_CATEGORY, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}

export const updateCategory = (id, data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/category/update/${id}`, data);
      debugger
      dispatch({ type: UPDATE_CATEGORY, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}

export const approveCategory = (data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/category`, data);
      debugger
      dispatch({
        type: UPDATE_CATEGORY, payload: {
          _id: data.id, status: data.status
        }
      });
      return response;
    } catch (error) {
      throw error
    }
  }
}


export const deleteCategory = (id) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.delete(`${baseUrl}/category/${id}`);
      dispatch({ type: DELETE_CATEGORY, payload: { _id: id } });
      return response;
    } catch (error) {
      throw error
    }
  }
}



