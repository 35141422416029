import { GET_CATEGORY, ADD_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY } from "../actions/categoryActions";

const initState = {
  category: [],
  loading: false,
}

const categoryReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_CATEGORY:
      return { ...state, category: action.payload }
    case ADD_CATEGORY:
      debugger
      return { ...state, category: [action.payload, ...state.category] }
    case UPDATE_CATEGORY:
      return {
        ...state, category: state.category.map((ele) => {
          return action.payload._id === ele._id ? {
            ...ele, ...action.payload,
          } : ele;
        })
      }
    case DELETE_CATEGORY:
      return {
        ...state,
        category: state.category.filter((ele) => action.payload._id !== ele._id)
      }
    default:
      return state;
  }
}

export default categoryReducers;