const routes = {
  //changePassword: {
  // visibility: [],
  //  path: "/change-password",
  //  name: "Change Password",
  //  icon: "fa fa-key",
  //  access: ['mentor', 'admin']
  //},
  //settings: {
  //visibility: [],
  //  path: "/settings",
  //  name: "Settings",
  //  icon: "fa fa-gears",
  //  access: ['admin']
  //},
  //forgetPassword: {
  //  path: "/auth/forget-password",
  //  name: "Forget password",
  //  icon: "fa fa-meh-o",
  //  access: []
  //},
  login: {
    visibility: [],
    path: "/auth/login",
    name: "Trainee List",
    icon: "fa fa-meh-o",
    access: []
  },
  category: {
    visibility: ['sidebar'],
    path: "/category",
    name: "Category List",
    icon: "fa fa-list",
    access: []
  },
  brand: {
    visibility: ['sidebar'],
    path: "/brands",
    name: "Brand List",
    icon: "fa fa-th-large",
    access: []
  },
  seller: {
    visibility: [],
    //visibility: ['sidebar'],
    path: "/sellers",
    name: "Seller List",
    icon: "fa fa-list-alt",
    access: []
  },
  sellerProduct: {
    visibility: ['sidebar'],
    path: "/sellers-product",
    name: "Seller Product",
    icon: "fa fa-shopping-bag",
    access: []
  },
  product: {
    visibility: ['sidebar'],
    path: "/products",
    name: "Product List",
    icon: "fa fa-cubes",
    access: []
  },
  auction: {
    visibility: [],
    //visibility: ['sidebar'],
    path: "/auctions",
    name: "Auction List",
    icon: "fa fa-gavel",
    access: [],
    child: {
      list: {
        visibility: ['sidebar'],
        path: "/auctions/list",
        name: "List",
        icon: "fa fa-list-ul",
        access: []
      },
      bluk: {
        visibility: ['sidebar'],
        path: "/auctions/bluk",
        name: "Bulk",
        icon: "fa fa-list-ul",
        access: []
      }
    },
  },
  orders: {
    visibility: [],
    //visibility: ['sidebar'],
    path: "/orders",
    name: "Orders",
    icon: "fa fa-dropbox",
    access: [],
    menuName: 'orderPagesMenuOpen',
    child: {
      list: {
        visibility: ['sidebar'],
        path: "/order/list",
        name: "List",
        icon: "fa fa-list-ul",
        access: []
      },
      download: {
        visibility: ['sidebar'],
        path: "/order/download",
        name: "Download",
        icon: "fa fa-list-ul",
        access: []
      }
    },
  },
  users: {
    visibility: [],
    //visibility: ['sidebar'],
    path: "/users",
    name: "Users",
    icon: "fa fa-users",
    access: [],
    menuName: 'userPagesMenuOpen',
    child: {
      overView: {
        visibility: ['sidebar'],
        path: "/user/over-view",
        name: "Over View",
        icon: "fa fa-user-o",
        access: []
      },
      notification: {
        visibility: ['sidebar'],
        path: "/user/notifications",
        name: "Notifications",
        icon: "fa fa-user-o",
        access: []
      },
      userDetails: {
        visibility: ['sidebar'],
        path: "/user/user-details",
        name: "User Details",
        icon: "fa fa-user-o",
        access: []
      },
    }
  },
}

export const routesConst = {
  routes,
  access: {
    //admin: [
    //  routes.employee.path,
    //  routes.mentor.path,
    //  routes.settings.path,
    //  routes.changePassword.path,
    //],
    //mentor: [
    //  routes.trainee.path,
    //  routes.changePassword.path,
    //]
  }
}
