import { baseUrl } from '../config';
import { fetchWrapper } from '../_helper'

export class AuthService {
  static forgetPassword(data) {
    return fetchWrapper.post(baseUrl + '/auth/forget-password', data)
  }

  static verifyCode(data) {
    return fetchWrapper.post(baseUrl + `/auth/verify-code`, data)
  }

}
