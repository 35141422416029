import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import Spinner from './../components/Spinner';
import PrivateRoute from './../components/PrivateRoute';

import AuthRouter from './auth.router';
import ProtectedRouter from './protected.router';


function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path="/auth" component={AuthRouter} />
        <PrivateRoute path="/" component={ProtectedRouter} />
      </Switch>
    </Suspense>
  );
}

export default AppRoutes;