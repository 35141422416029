export const enums = {
  trainingStatus: {
    "completed": "Completed",
    "done": "Done",
  },
  assignmentStatus: {
    "completed": "Completed",
    "done": "Done",
  },
  //status: [
  //  "active",
  //  "inactive",
  //],
  //verification: ["yes", "no"]
  status: {
    "ACTIVE": "active",
    "IN_ACTIVE": "inactive"
  },
  verification: {
    "YES": "yes",
    "NO": "no"
  }
}