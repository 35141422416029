import { baseUrl } from "../../config";
import { fetchWrapper } from './../../_helper'
import { AUTHENTICATE_USER, LOGOUT_USER } from "../actionTypes/auth.actionTypes";

export const authenticate = (payload) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/users/verify`, payload);
      debugger
      localStorage.setItem('user', JSON.stringify(response));
      await dispatch({ type: AUTHENTICATE_USER, payload: response });
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

export const changePassword = ({ confirmPassword, password, oldPassword }) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/user/change-password`, { password, oldPassword, });
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

export const logout = () => dispatch => {
  //localStorage.removeItem('user');
  dispatch({ type: LOGOUT_USER, payload: null });
}


