import { GET_SELLER, UPDATE_SELLER, ADD_SELLER } from "../actions/sellerActions";

const initState = {
  seller: [],
  loading: false,
}

const sellerReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_SELLER:
      return { ...state, seller: action.payload }
    case ADD_SELLER:
      debugger
      return { ...state, seller: [action.payload, ...state.seller] }
    case UPDATE_SELLER:
      return {
        ...state, seller: state.seller.map((ele) => {
          return action.payload._id === ele._id ? {
            ...action.payload
          } : ele;
        })
      }
    default:
      return state;
  }
}

export default sellerReducers;