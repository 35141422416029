import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import thunk from 'redux-thunk';

import authReducers from './reducers/authReducers';
import settingsReducers from './reducers/settingsReducers';
import sellerReducers from './reducers/sellerReducers';
import productReducers from './reducers/productReducers';
import brandReducers from './reducers/brandReducers';
import auctionReducers from './reducers/auctionReducers';
import categoryReducers from './reducers/categoryReducers';

const rootReducers = combineReducers({
  auth: authReducers,
  settings: settingsReducers,
  seller: sellerReducers,
  product: productReducers,
  brand: brandReducers,
  auction: auctionReducers,
  category: categoryReducers,
});

// BINDING MIDDLEWARE
const bindMiddleware = (middleware) => {
  if (true) return composeWithDevTools({ name: "qoOnboarding" })(applyMiddleware(...middleware));
  //return applyMiddleware(...middleware);
};
//export const store = createStore(rootReducers, {}, bindMiddleware([thunk]));

//export default (initialState = {}, history) => {
//  const persistedReducer = persistReducer(persistConfig, rootReducers)

//  const store = createStore(persistedReducer, initialState)

//  const persistor = persistStore(store)

//  return { store, persistor }
//}



function configureStore(initialState = {}) {

  //const reducer = combineReducers({
  //  //auth: () => ({ mock: true }),
  //  form: persistReducer(
  //    {
  //      key: "qOnboarding", // key for localStorage key, will be: "persist:form"
  //      storage,
  //      debug: true,
  //      //blacklist: ['foo'],
  //    },
  //    rootReducers
  //  ),
  //});

  const store = createStore(persistReducer({
    key: "root",
    debug: true,
    storage,
    whitelist: ['auth'],
  }, rootReducers), initialState, bindMiddleware([thunk]));

  console.log("initialState", store.getState());

  const persistor = persistStore(store, null, () => {
    // if you want to get restoredState
    console.log("restoredState", store.getState());
  });

  return { store, persistor };
}

export default configureStore;
