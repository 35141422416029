import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from "react-redux";

//import { accountService } from './../_services';

//import Login from './../pages/auth/Login';
import ForgetPassword from './../pages/auth/ForgetPassword';
import MobileLogin from './../pages/auth/MobileLogin';

function Auth({ history, match }) {
  const { path } = match;
  const user = useSelector((state) => state.auth.isAuthenticated);
  useEffect(() => {
    // redirect to home if already logged in
    if (user) { history.push('/'); }
  }, [history, user]);

  return (
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="main-panel">
        <div className="content-wrapper">
          <Switch>
            <Route path={`${path}/login`} component={MobileLogin} />
            {/*<Route path={`${path}/login`} component={Login} />*/}
            <Route path={`${path}/forgot-password`} component={ForgetPassword} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Auth;