

//import { AUTHENTICATE_USER, LOGOUT_USER } from "../actions/authActions";
import { AUTHENTICATE_USER, LOGOUT_USER } from "../actionTypes/auth.actionTypes";

const initState = { isAuthenticated: false, user: {}, token: '', role: null, data: {} }

const authReducers = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      const { user, access_token, role } = action.payload;
      return { ...state, isAuthenticated: true, user: user, token: access_token, role, data: action.payload }
    case LOGOUT_USER:
      return { ...state, isAuthenticated: false, user: {}, token: '' }
    default:
      return state;
  }
}

export default authReducers;