import React from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as authActions from '../../store/actions/authActions';
//import { history } from './../../_helper'

const ForGetPassword = ({ location }) => {
  const dispatch = useDispatch();

  const initialValues = {
    mobileNumber: '',
    countryCode: '91',
    password: '',
    otp: '',
  };

  const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string().required('Mobile number is required'),
    password: Yup.string().required('Password is required')
  });


  const onSubmit = async ({ mobileNumber, countryCode = '91', password, }, { setSubmitting }) => {
    try {
      await dispatch(authActions.authenticate({ mobileNumber, countryCode: '91', password }))
      //const { from } = location.state || {
      //  from: { pathname: data.user.isAdmin ? routesConst.routes.employee.path : routesConst.routes.trainee.path }
      //};
      //history.push(routesConst.routes.login.path)
    } catch (error) {
      toast.error(error.message)
      setSubmitting(false);
    }
  }


  return (
    <div>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ errors, touched, isSubmitting }) => {

                  return (
                    <FormikForm>

                      <div className="card-body">
                        <h6 className=" pb-3 text-center">Admin login</h6>

                        <div className="form-group">
                          <label>Mobile number</label>
                          <Field name="mobileNumber" type="text" placeholder="Enter mobile number" className={'form-control' + (errors.mobileNumber && touched.mobileNumber ? ' is-invalid' : '')} />
                          <ErrorMessage name="mobileNumber" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group">
                          <label>password</label>
                          <Field name="password" type="password" placeholder="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                          <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-row mt-3 pt-1 pb-1">
                          <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-lg font-weight-medium btn-block auth-form-btn" >
                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            {"Login"}
                          </button>
                        </div>

                      </div>
                    </FormikForm>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForGetPassword
