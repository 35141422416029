import { baseUrl } from "../../config";
import { fetchWrapper } from '../../_helper'
export const GET_PRODUCT = 'GET_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';

export const getProduct = (data = {}) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/products`, data);
      dispatch({ type: GET_PRODUCT, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}

export const updateProduct = (id, data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/products/update/${id}`, data);
      debugger
      dispatch({ type: UPDATE_PRODUCT, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}
export const addProduct = (data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/products/add`, data);
      dispatch({ type: ADD_PRODUCT, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}



