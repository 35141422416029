import { baseUrl } from "../../config";
import { fetchWrapper } from '../../_helper'
export const GET_SELLER = 'GET_SELLER';
export const UPDATE_SELLER = 'UPDATE_SELLER';
export const ADD_SELLER = 'ADD_SELLER';

export const getSeller = (data = {}) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.get(`${baseUrl}/sellers`, data);
      dispatch({ type: GET_SELLER, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}

export const updateSeller = (id, data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.patch(`${baseUrl}/sellers/update/${id}`, data);
      debugger
      dispatch({ type: UPDATE_SELLER, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}
export const addSeller = (data) => {
  return async dispatch => {
    try {
      const response = await fetchWrapper.post(`${baseUrl}/sellers/add`, data);
      dispatch({ type: ADD_SELLER, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}



