import { logout } from './../store/actions/authActions';
import storeConfig from '../store'

const { store } = storeConfig()

class fetchWrapper {

  static get(url, params = {}) {
    let query = Object.keys(params)
      .map(k => k + '=' + params[k])
      .join('&');
    const requestOptions = {
      method: 'GET',
      headers: fetchWrapper.authHeader(url)
    };
    return fetch(url + (query ? `?${query}` : ""), requestOptions).then(fetchWrapper.handleResponse);
  }

  static post(url, body, { fromData } = {}) {
    const header = { 'Content-Type': 'application/json', ...fetchWrapper.authHeader() };
    if (fromData) {
      delete header['Content-Type'];
    } else {
      body = JSON.stringify(body)
    }
    const requestOptions = {
      method: 'POST',
      headers: header,
      //credentials: 'include',
      body: body
    };
    return fetch(url, requestOptions).then(fetchWrapper.handleResponse)
  }

  static put(url, body, { fromData } = {}) {
    const header = { 'Content-Type': 'application/json', ...fetchWrapper.authHeader(url) };
    if (fromData) {
      delete header['Content-Type'];
    } else {
      body = JSON.stringify(body)
    }
    const requestOptions = {
      method: 'PUT',
      headers: header,
      body: body
    };
    return fetch(url, requestOptions).then(fetchWrapper.handleResponse);
  }

  static delete(url, body) {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', ...fetchWrapper.authHeader(url) },
      body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(fetchWrapper.handleResponse);
  }

  static patch(url, body) {

    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', ...fetchWrapper.authHeader(url) },
      body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(fetchWrapper.handleResponse);
  }

  static authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
    //const user = select(store.getState());
    const isLoggedIn = user;
    //const isApiUrl = url.startsWith(config.baseUrl);
    if (isLoggedIn) {
      //if (isLoggedIn && isApiUrl) {
      return { 'authorization': `Bearer ${user.access_token}` };
      // return { Authorization: `Bearer ${user.jwtToken}` };
    } else {
      return {};
    }
  }

  static handleResponse(response) {
    return response.text().then(text => {
      if (response.headers.get('Content-Type').includes("application/pdf")) {
        return text;
      }
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if ([401].includes(response.status)) {
          // auto logout if 401 Unauthorized response returned from api
          store.dispatch(logout())
          //window.location.reload()
        }

        const message = (data && data.message) || response.statusText;
        return Promise.reject({ message });
      }

      return data;
    });
  }
}

export default fetchWrapper

