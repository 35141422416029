
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";

function PrivateRoute({ component: Component, roles, ...rest }) {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Route {...rest} render={props => {
      // not logged in so redirect to login page with the return url
      if (!isAuthenticated) return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />

      // authorized so return component
      return <Component {...props} />
    }} />
  );
}

export default PrivateRoute;