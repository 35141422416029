
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './router';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from "redux-persist/lib/integration/react";

import configureStore from "./store";
const { store, persistor } = configureStore();

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter basename="/">
        <ToastContainer />
        <AppRoutes />
      </BrowserRouter >
    </PersistGate>
  </Provider>

);

export default App;
