import { baseUrl } from "../../config";
import { fetchWrapper } from './../../_helper'
export const GET_DESIGNATION = 'GET_DESIGNATION';
export const ADD_DESIGNATION = 'ADD_DESIGNATION';
export const UPDATE_DESIGNATION = 'UPDATE_DESIGNATION';
export const GET_TRAINING = 'GET_TRAINING';
export const ADD_TRAINING = 'ADD_TRAINING';
export const UPDATE_TRAINING = 'UPDATE_TRAINING';
export const GET_STATUS = 'GET_STATUS';
export const ADD_STATUS = 'ADD_STATUS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const tabConst = {
  //status: {
  //  name :"status",
  //  apiPath :"status"
  //},
  training: "training",
  designation: "designation",
  status: "status",
};

export const getSettings = ({ type }) => {
  return async dispatch => {
    try {
      if (!Object.values(tabConst).includes(type)) throw Error("Invalid tab type get")
      const response = await fetchWrapper.get(`${baseUrl}/configuration/${type}`);
      if (type === tabConst.designation) dispatch({ type: GET_DESIGNATION, payload: response.data });
      if (type === tabConst.status) dispatch({ type: GET_STATUS, payload: response.data });
      if (type === tabConst.training) dispatch({ type: GET_TRAINING, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}

export const updateSettings = (id, data, type) => {
  return async dispatch => {
    try {
      if (!Object.values(tabConst).includes(type)) throw Error("Invalid tab type to update")
      const response = await fetchWrapper.put(`${baseUrl}/configuration/${type}/${id}`, data);
      if (type === tabConst.designation) dispatch({ type: UPDATE_DESIGNATION, payload: response.data });
      if (type === tabConst.status) dispatch({ type: UPDATE_STATUS, payload: response.data });
      if (type === tabConst.training) dispatch({ type: UPDATE_TRAINING, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}
export const addSettings = (data, type) => {
  return async dispatch => {
    try {
      if (!Object.values(tabConst).includes(type)) throw Error("Invalid tab type for add")
      const response = await fetchWrapper.post(`${baseUrl}/configuration/${type}`, data);
      if (type === tabConst.designation) dispatch({ type: ADD_DESIGNATION, payload: response.data });
      if (type === tabConst.status) dispatch({ type: ADD_STATUS, payload: response.data });
      if (type === tabConst.training) dispatch({ type: ADD_TRAINING, payload: response.data });
      return response;
    } catch (error) {
      throw error
    }
  }
}
