import { GET_PRODUCT, ADD_PRODUCT, UPDATE_PRODUCT } from "../actions/productActions";

const initState = {
  product: [],
  loading: false,
}

const productReducers = (state = initState, action) => {
  switch (action.type) {
    case GET_PRODUCT:
      return { ...state, product: action.payload }
    case ADD_PRODUCT:
      debugger
      return { ...state, product: [action.payload, ...state.product] }
    case UPDATE_PRODUCT:
      return {
        ...state, product: state.product.map((ele) => {
          return action.payload._id === ele._id ? {
            ...action.payload
          } : ele;
        })
      }
    default:
      return state;
  }
}

export default productReducers;